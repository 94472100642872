import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { Trans } from 'next-i18next';

import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import Box from '@components/elements/Box';
import Tab from '@components/elements/Tabs/Tab';
import TabPanel from '@components/elements/Tabs/TabPanel';
import Tabs from '@components/elements/Tabs/Tabs';
import RegisterForm from '@components/modules/RegisterForm/RegisterForm';
import { triggerDeclarationEvent } from '@utils/gtmTs';

const LoginForm = dynamic(() =>
  import('@components/modules/LoginForm/LoginForm')
);

const Customer = () => {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleChangeTab = index => {
    setActiveTabIndex(index);
  };

  const hasParagraph = !isEmpty(
    t('$*newOrderCreatePage.orderForm.customer.loginParagraph')
  );
  const hasRegisterParagraph = !isEmpty(
    t('$*newOrderCreatePage.orderForm.customer.notHaveAccount')
  );

  return (
    <div tw="flex justify-center">
      <div className="col--main-center">
        <Box>
          <Tabs activeTabIndex={activeTabIndex} onChange={handleChangeTab}>
            <Tab
              label={t(
                '$*newOrderCreatePage.orderForm.customer.register',
                'Zarejestruj'
              )}
              index={0}
              data-cy="tab-auth--register"
            />
            <Tab
              label={t(
                '$*newOrderCreatePage.orderForm.customer.login',
                'Zaloguj'
              )}
              index={1}
              data-cy="tab-auth--login"
            />
          </Tabs>
          <TabPanel activeTabIndex={activeTabIndex} index={0}>
            <div tw="pt-3">
              <RegisterForm />
            </div>
          </TabPanel>
          <TabPanel activeTabIndex={activeTabIndex} index={1}>
            <div tw="pt-3">
              {hasParagraph && (
                <p tw="mb-4 md:mb-5 lg:mb-8 text-gray-3">
                  {t(
                    '$*newOrderCreatePage.orderForm.customer.loginParagraph',
                    'Witaj ponownie! Zaloguj się do swojego konta.'
                  )}
                </p>
              )}
              <LoginForm onAfterLogin={triggerDeclarationEvent} />

              {hasRegisterParagraph && (
                <div tw="mt-10 pt-4 text-center border-t border-gray-1">
                  <Trans
                    i18nKey="$*newOrderCreatePage.orderForm.customer.notHaveAccount"
                    defaults="Nie masz konta? <0>Zarejestruj się</0>"
                    components={[
                      <span
                        tw="font-semibold cursor-pointer text-primary transition-colors duration-300 focus:(transition-colors text-primary-hover)"
                        key="0"
                        onClick={() => handleChangeTab(0)}
                      />,
                    ]}
                  />
                </div>
              )}
            </div>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default Customer;
