import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import Box from '@components/elements/Box';
import PaymentOptions from '@components/modules/Basket/BasketNormal/PaymentOptions';
import useBasket from '@hooks/useBasket';
import useMediaQuery from '@hooks/useMediaQuery';
import { checkIfStandardPaymentTypesAvailable } from '@utils/paymentTypesHelpers';
import { up } from '@utils/screens';

import OrderItems from './OrderItems/OrderItems';
import OrderDetails from './OrderDetails';

const Summary = () => {
  const { t } = useTranslation();

  const isUpXl = useMediaQuery(up('xl'), true);

  const { data: basket = {} } = useBasket({
    basketKey: 'basketNewOrder',
  });

  const areStandardPaymentTypesAvailable = basket?.paymentTypes
    ? checkIfStandardPaymentTypesAvailable(basket.paymentTypes)
    : false;

  return (
    <div tw="flex flex-col xl:flex-row">
      <div tw="w-full xl:w-3/4">
        <OrderDetails />
        <OrderItems />
      </div>
      <div tw="w-full xl:w-1/2 xl:ml-8">
        <Box size="md" styles={{ css: tw`relative mt-4 lg:mt-0` }}>
          {areStandardPaymentTypesAvailable && (
            <h3 className="h3" tw="pb-1.5 mb-8 border-b border-gray-1">
              {t('$*newOrderCreatePage.orderForm.summary.paymentTypes')}
            </h3>
          )}
          <PaymentOptions showBasketPay={isUpXl} />
        </Box>
      </div>
    </div>
  );
};

export default Summary;
