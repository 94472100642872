import { useTranslation } from 'next-i18next';

import { useState } from 'react';
import tw from 'twin.macro';

import Button from '@components/elements/Button';
import ApiLoadingBoundary from '@components/elements/Loading/ApiLoadingBoundary';
import useBasket from '@hooks/useBasket';
import useBasketMethods from '@hooks/useBasketMethods';
import usePickupPoints from '@hooks/usePickupPoints';
import showToast from '@utils/showToast';

type PickUpPointsProps = {
  isLoading: boolean;
};

const PickUpPoints = ({ isLoading }: PickUpPointsProps) => {
  const { t } = useTranslation();

  const {
    data: { defaultPickUpPoint },
  } = useBasket({
    basketKey: 'basketNewOrder',
  });

  const { modifyBasket } = useBasketMethods() as unknown as {
    modifyBasket: (basketItem: {
      delivery: { pickUpPoint: string };
    }) => Promise<void>;
  };

  const {
    data: pickUpPoints = [],
    isLoading: isPickUpPointsLoading,
    isError: isPickUpPointsError,
  } = usePickupPoints();

  const [isModifyingBasket, setIsModifyingBasket] = useState(false);

  return (
    <ApiLoadingBoundary
      isError={isPickUpPointsError}
      isLoading={isLoading || isPickUpPointsLoading || isModifyingBasket}
    >
      <p>{t('$*newOrderCreatePage.orderForm.delivery.pickupDiscount')}</p>
      <div tw="grid gap-y-4">
        {pickUpPoints.map(pickUpPoint => {
          const isSelected = pickUpPoint.id === defaultPickUpPoint?.id;

          return (
            <Button
              css={isSelected ? tw`text-white bg-primary` : undefined}
              key={pickUpPoint.id}
              variant="outlined"
              fullWidth
              onClick={async () => {
                setIsModifyingBasket(true);

                try {
                  await modifyBasket({
                    delivery: {
                      pickUpPoint: pickUpPoint['@id'],
                    },
                  });
                } catch (error) {
                  showToast(t('$*error.api.unknown'), {
                    type: 'error',
                  });
                } finally {
                  setIsModifyingBasket(false);
                }
              }}
            >
              {pickUpPoint.value}
            </Button>
          );
        })}
      </div>
    </ApiLoadingBoundary>
  );
};

export default PickUpPoints;
