import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RenderPropSticky } from 'react-sticky-el';
import { useDeepCompareEffect } from 'react-use';
import { selectCurrentDiet } from '@store/basket/basketNewOrder.slice';
import { selectDisableTracking } from '@store/nativeAppConfig/nativeAppConfig.slice';
import tw from 'twin.macro';

import ChevronUpIcon from '@assets/icons/ChevronUpIcon';
import Button from '@components/elements/Button';
import ConditionalWrapper from '@components/elements/ConditionalWrapper';
import AddMoreDietButton from '@components/modules/AddMoreDietButton/AddMoreDietButton';
import BasketAction from '@components/modules/Basket/BasketNormal/BasketAction';
import MobileBasket from '@components/modules/Basket/BasketNormal/MobileBasket';
import BasketActionStatic from '@components/modules/Basket/BasketStatic/BasketAction';
import useBasketPrices from '@components/modules/Basket/BasketStatic/useBasketPrices';
import ChooseDietToEditModal from '@components/modules/ChooseDietToEditModal/ChooseDietToEditModal';
import MultipleDietsBanner from '@components/modules/MultipleDietsBanner/MultipleDietsBanner';
import BASKET_ROWS_TYPES from '@constants/basketRowsTypes';
import TAB_SLUGS from '@constants/tabSlugs';
import {
  selectBranding,
  selectModuleConfigClientPanel,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasket from '@hooks/useBasket';
import useBasketMethods from '@hooks/useBasketMethods';
import useMediaQuery from '@hooks/useMediaQuery';
import {
  pushCheckoutStepGTMEvent,
  pushViewCartGTMEvent,
  pushViewCartNewGTMEvent,
} from '@utils/gtm';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';
import { down, up } from '@utils/screens';

import {
  changeStep,
  prevStep,
  selectTabs,
  setIsAnimating,
  setTabsInitialState,
  updateSteps,
} from './orderTabsSlice';
import Tab from './Tab';
import Underline from './Underline';
import useTabsConfig from './useTabsConfig';

const BasketPay = dynamic(() =>
  import('@components/modules/Basket/BasketNormal/BasketPay')
);

const slugsWithNotSelectedDiets = [TAB_SLUGS.DELIVERY_DATA, TAB_SLUGS.SUMMARY];

const OrderTabs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isPrevDisabled,
    currentStepIndex,
    steps,
    isAnimating,
    isInitialStateSet,
    stepsCount,
  } = useSelector(selectTabs);

  const isUpMd = useMediaQuery(up('md'), true);
  const isUpXl = useMediaQuery(up('xl'), true);
  const isDownXl = useMediaQuery(down('xl'), true);

  const { name: brandName } = useAppConfigSelector(selectBranding);
  const { allowStickyNextButton } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const {
    currencySymbol,
    defaultRegion: { currencyCode },
  } = useAppConfigSelector(selectMultinational);
  const { isMutatingBasket, isBasketStatic } = useBasketMethods();
  const currentDiet = useSelector(selectCurrentDiet);

  const { data: basket } = useBasket({
    basketKey: 'basketNewOrder',
  });

  const dietElements = isBasketStatic
    ? [currentDiet]
    : basket.rows?.filter(
        row => row['@type'] === BASKET_ROWS_TYPES.DIET_ITEM
      ) || [];

  const basketPrices = useBasketPrices({ dietElements });
  const disableTracking = useSelector(selectDisableTracking);

  const [activatedTabIndex, setActivatedTabIndex] = useState(null);
  const [activatedTabSlug, setActivatedTabSlug] = useState(null);

  const tabsConfig = useTabsConfig();
  const tabs = tabsConfig.map(tab => {
    const tabCopy = { ...tab };
    delete tabCopy.panel;
    return tabCopy;
  });
  const panels = tabsConfig.map(({ panel }) => panel);

  useEffect(() => {
    if (!isInitialStateSet) {
      const tabsWithValid = tabs.map(tab => ({
        ...tab,
        isValid: false,
      }));

      dispatch(setTabsInitialState(tabsWithValid));
    }
  }, [isInitialStateSet]);

  useDeepCompareEffect(() => {
    dispatch(updateSteps(tabs));
  }, [tabs, currentStepIndex]);

  useEffect(() => {
    if (!disableTracking) {
      pushCheckoutStepGTMEvent({
        step: currentStepIndex + 1,
      });
    }
  }, [currentStepIndex]);

  useEffect(() => {
    pushViewCartGTMEvent({
      brandName,
      currencyCode,
      discountCode: basket?.discountCode?.code,
      items: basket?.rows,
      price: basket?.price?.afterDiscount,
    });
  }, []);

  const currentStep = steps[currentStepIndex] ?? {};
  const isAuthStep = currentStep?.slug === TAB_SLUGS.AUTHORIZATION;
  const isSummaryStep = currentStep?.slug === TAB_SLUGS.SUMMARY;

  useEffect(() => {
    if (isSummaryStep) {
      pushViewCartNewGTMEvent({
        brandName,
        currencyCode,
        discountCode: basket?.discountCode?.code,
        items: basket?.rows,
        price: basket?.price?.afterDiscount,
      });
    }
  }, [isSummaryStep]);

  const isFirstStep = currentStepIndex === 0;
  const stepRefs = steps.reduce((acc, _, index) => {
    acc[index] = createRef();
    return acc;
  }, {});

  const scrollToTop = () => window.scrollTo({ top: 0 });

  const handleTabClick = (tabIndex, tabSlug) => {
    setActivatedTabSlug(tabSlug);
    if (
      dietElements.length <= 1 ||
      !slugsWithNotSelectedDiets.includes(currentStep.slug) ||
      slugsWithNotSelectedDiets.includes(tabSlug)
    ) {
      dispatch(changeStep(tabIndex));
      scrollToTop();
    } else {
      setActivatedTabIndex(tabIndex);
    }
  };

  const handleAfterChooseDietModalClose = () => {
    dispatch(changeStep(activatedTabIndex));
    scrollToTop();
  };

  const handleCloseChooseDietModal = () => {
    setActivatedTabIndex(null);
  };

  const handlePrevTab = () => {
    dispatch(prevStep());
    scrollToTop();
  };

  const isLastStep = currentStepIndex === stepsCount - 1;
  const isBasketShown = isFirstStep && !isUpXl;
  const isBasketActionShown = !isFirstStep && !isUpXl && !isAuthStep;
  const isBasketPayShown = !isUpXl && isSummaryStep;
  const isPreviousShown = !isFirstStep && !isUpXl;

  const areBasketElementsShown =
    isBasketShown || isBasketActionShown || isBasketPayShown;

  return (
    <>
      <div
        {...(areBasketElementsShown && { css: tw`mb-12` })}
        className="order-tabs container"
      >
        <div
          className="order-tabs__list"
          tw="bg-white rounded-md shadow-lg mb-8 relative flex-nowrap overflow-auto"
        >
          <ul
            aria-orientation="horizontal"
            className="order-tabs__nav"
            role="tablist"
            tw="flex"
          >
            {steps.map(({ label, slug }, index) => {
              const prevTabIsValid = steps[index - 1]?.isValid ?? false;
              const currentTabIsValid = steps[index].isValid;
              const isActive = currentStepIndex === index;
              const currentTabIsDisabled =
                (prevTabIsValid ? !prevTabIsValid : !currentTabIsValid) ||
                (isMutatingBasket && !isActive);

              return (
                <Tab
                  key={index}
                  disabled={currentTabIsDisabled}
                  isActive={isActive}
                  ref={stepRefs[index]}
                  onClick={() => handleTabClick(index, slug)}
                >
                  <span tw="text-xl md:text-3xl">{index + 1}</span>
                  {(isUpMd || isActive) && (
                    <span tw="uppercase text-sm pl-4">{label}</span>
                  )}
                </Tab>
              );
            })}
          </ul>
          <Underline
            refs={stepRefs}
            activeTab={currentStepIndex}
            finishAnimating={() => dispatch(setIsAnimating(false))}
            animating={isAnimating}
          />
        </div>
        {isDownXl && <MultipleDietsBanner />}
        {panels.map((PanelComponent, index) => {
          if (index !== currentStepIndex) {
            return null;
          }

          return (
            <div key={index} role="tabpanel" className="order-tabs__panel">
              <PanelComponent currentStepNumber={currentStepIndex + 1} />
            </div>
          );
        })}
      </div>
      {areBasketElementsShown && (
        <RenderPropSticky
          mode="bottom"
          positionRecheckInterval={500}
          disabled={!(allowStickyNextButton ?? true)}
        >
          {({
            isFixed,
            wrapperStyles,
            wrapperRef,
            holderStyles,
            holderRef,
          }) => (
            <div ref={holderRef} style={holderStyles}>
              <div
                css={[
                  tw`z-50`,
                  isFixed &&
                    tw`py-5 flex flex-col justify-center bg-white border border-solid border-gray-1`,
                ]}
                style={wrapperStyles}
                ref={wrapperRef}
              >
                <div className="container">
                  {isBasketShown && <MobileBasket />}
                  {isBasketActionShown &&
                    (isBasketStatic ? (
                      <BasketActionStatic
                        buttonNextText={t('$*components.mobileBasket.button', {
                          replace: {
                            price: getIntegerIfIntegerOrFixed(
                              basketPrices?.price?.afterDiscount ?? 0
                            ),
                            currencySymbol,
                          },
                        })}
                      />
                    ) : (
                      <BasketAction
                        buttonNextText={t('$*components.mobileBasket.button', {
                          replace: {
                            price: getIntegerIfIntegerOrFixed(
                              basket.price?.afterDiscount ?? 0
                            ),
                            currencySymbol,
                          },
                        })}
                      />
                    ))}
                  {isBasketPayShown && <BasketPay styles={tw`mt-2`} />}
                  {isLastStep && (
                    <AddMoreDietButton wrapperTwStyle={tw`mt-2`} />
                  )}
                </div>
              </div>
            </div>
          )}
        </RenderPropSticky>
      )}
      <div className="container">
        {isPreviousShown && (
          <ConditionalWrapper
            condition={!areBasketElementsShown}
            wrapper={children => <div tw="pt-6">{children}</div>}
          >
            <Button
              onClick={handlePrevTab}
              disabled={isPrevDisabled}
              variant="outlined"
              tw="border-none text-[16px] p-0 mt-11 flex items-center"
              data-cy="order-tabs__button--prev"
            >
              <ChevronUpIcon tw="-rotate-90 w-3 mr-2 ml-0" />{' '}
              {t('$*components.orderTabs.prevButton.label')}
            </Button>
          </ConditionalWrapper>
        )}
        {isUpXl && !isFirstStep && !isSummaryStep && (
          <div tw="flex mt-9 justify-between flex-wrap -mx-4 -mb-2">
            <Button
              onClick={handlePrevTab}
              disabled={isPrevDisabled}
              variant="outlined"
              styles={{
                button: tw`justify-center flex-auto mx-4 my-2 sm:flex-initial`,
              }}
              data-cy="order-tabs__button--prev"
            >
              {t('$*components.orderTabs.prevButton.label')}
            </Button>
          </div>
        )}
      </div>
      <ChooseDietToEditModal
        activatedTabSlug={activatedTabSlug}
        isOpen={activatedTabIndex !== null}
        handleClose={handleCloseChooseDietModal}
        handleAfterClose={handleAfterChooseDietModalClose}
      />
    </>
  );
};

export default OrderTabs;
