import { type LiHTMLAttributes, forwardRef } from 'react';
import tw from 'twin.macro';

type TabProps = {
  index: number;
  label: string;
  disabled?: boolean;
  activeTabIndex?: number;
  onClick?: (index: number) => void;
} & LiHTMLAttributes<HTMLLIElement>;

const Tab = forwardRef<HTMLLIElement, TabProps>(
  (
    { disabled = false, activeTabIndex, index, label, onClick, ...liProps },
    ref
  ) => {
    const twStyle = [
      tw`flex items-center flex-auto px-2 py-1 text-lg font-semibold cursor-pointer`,
      disabled && tw`text-gray-1 cursor-default`,
      activeTabIndex === index && tw`text-primary`,
    ];

    return (
      <li
        css={twStyle}
        ref={ref}
        onClick={() => {
          if (!disabled && onClick) {
            onClick(index);
          }
        }}
        role="presentation"
        {...liProps}
      >
        {label}
      </li>
    );
  }
);

Tab.displayName = 'Tab';

export default Tab;
