import {
  Children,
  cloneElement,
  createRef,
  isValidElement,
  useState,
} from 'react';
import tw from 'twin.macro';

import Underline from './Underline';

const Tabs = ({ cssExtend, activeTabIndex, onChange, children }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        activeTabIndex,
        onClick: onChange,
        ref: createRef(),
      });
    }

    return child;
  });

  const stepRefs = childrenWithProps.map(({ ref }) => ref);

  return (
    <div css={[tw`relative pb-3 mb-4 overflow-hidden`, cssExtend]}>
      <ul role="tablist" aria-orientation="horizontal" tw="flex -mx-2 -my-1">
        {childrenWithProps}
      </ul>
      <Underline
        refs={stepRefs}
        activeTab={activeTabIndex}
        finishAnimating={() => setIsAnimating(false)}
        animating={isAnimating}
      />
    </div>
  );
};

Tabs.displayName = 'Tabs';

export default Tabs;
