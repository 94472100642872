import { useTranslation } from 'next-i18next';

import { useState } from 'react';
import tw from 'twin.macro';
import type { Address } from 'types/Address';

import ApiLoadingBoundary from '@components/elements/Loading/ApiLoadingBoundary';
import AddressesList from '@components/modules/AddressesList/AddressesList';
import useAppModuleConfig from '@hooks/useAppModuleConfig';
import useBasket from '@hooks/useBasket';
import useBasketMethods from '@hooks/useBasketMethods';
import useUserAddresses from '@hooks/useUserAddresses';
import showToast from '@utils/showToast';

type AddressesProps = {
  isLoading: boolean;
};

const Addresses = ({ isLoading }: AddressesProps) => {
  const { t } = useTranslation();
  const { allowStickyNextButton } = useAppModuleConfig('ConfigClientPanel');

  const {
    data: { defaultAddress },
  } = useBasket({
    basketKey: 'basketNewOrder',
  });

  const { modifyBasket } = useBasketMethods() as unknown as {
    modifyBasket: (basketItem: {
      delivery: { address: string };
    }) => Promise<void>;
  };

  const {
    data: addresses = [],
    isLoading: isAddressesLoading,
    isFetched: isAddressesFetched,
    isError: isAddressesError,
  } = useUserAddresses();

  const [isModifyingBasket, setIsModifyingBasket] = useState(false);

  const setAddress = async (address: Address) => {
    setIsModifyingBasket(true);

    try {
      await modifyBasket({
        delivery: {
          address: address['@id'],
        },
      });
    } catch (error) {
      showToast(t('$*error.api.unknown'), {
        type: 'error',
      });
    } finally {
      setIsModifyingBasket(false);
    }
  };

  return (
    <ApiLoadingBoundary
      isError={isAddressesError}
      isLoading={isLoading || isAddressesLoading || isModifyingBasket}
    >
      <AddressesList
        allowStickyNextButton={allowStickyNextButton}
        isFetched={isAddressesFetched}
        addresses={addresses as never[]}
        canAddNewAddress
        onClickAddress={setAddress}
        selectedAddresses={defaultAddress ? [defaultAddress.id] : []}
        onSuccessUpdateAddress={setAddress}
        onSuccessAddAddress={setAddress}
        styles={{
          address: {
            wrapper: tw`sm:max-w-[50%]`,
          },
        }}
      />
    </ApiLoadingBoundary>
  );
};

export default Addresses;
