import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { css, Global } from '@emotion/react';
import { selectOrderForm } from '@store/orderForm/orderForm.slice';

import Banner from '@components/elements/Banner/Banner';
import Seo from '@components/modules/Seo/Seo';
import OrderTabs from '@features/orderTabs/OrderTabs';
import { selectTabs } from '@features/orderTabs/orderTabsSlice';
import useOrderForm from '@hooks/useOrderForm';

import NewOrderCreatePageEffects from './NewOrderCreatePage.effects';

const NewOrderPageCreate = () => {
  const { t } = useTranslation();

  const { currentStepIndex } = useSelector(selectTabs);
  const { userProceed } = useSelector(selectOrderForm);

  const { data: { banner = {} } = {} } = useOrderForm({
    enabled: false,
  });

  return (
    <Fragment>
      <Seo pageName={t('$*newOrderCreatePage.pageName')} />
      <NewOrderCreatePageEffects />
      <Banner
        banner={banner}
        isDisabled={currentStepIndex > 0 || userProceed}
      />
      <h1 tw="hidden">{t('$*newOrderCreatePage.title')}</h1>
      <div tw="pt-8 pb-24">
        <Global
          styles={css`
            /* Hide scrollbar for Chrome, Safari and Opera */
            .order-tabs__list::-webkit-scrollbar,
            .basket__content::-webkit-scrollbar {
              display: none;
            }

            /* Hide scrollbar for IE, Edge and Firefox */
            .order-tabs__list,
            .basket__content {
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
            }
          `}
        />

        <OrderTabs />
      </div>
    </Fragment>
  );
};

export default NewOrderPageCreate;
