import { useTranslation } from 'next-i18next';

import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import Box from '@components/elements/Box';
import Button from '@components/elements/Button';
import AddMoreDietButton from '@components/modules/AddMoreDietButton/AddMoreDietButton';
import FinancialOptions from '@components/modules/Basket/BasketNormal/FinancialOptions';
import { prevStep } from '@features/orderTabs/orderTabsSlice';
import useBasket from '@hooks/useBasket';
import useMediaQuery from '@hooks/useMediaQuery';
import { down } from '@utils/screens';

import OrderItem from './OrderItem';

const OrderItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: basket = {} } = useBasket({
    basketKey: 'basketNewOrder',
  });
  const { others, diets } = basket.uiRows || {};

  const isDown1200 = useMediaQuery(down('1200px'), true);

  const hasDiets = !isEmpty(diets) && diets.length > 0;
  const hasOthers = !isEmpty(others);

  const handlePrevTab = () => {
    dispatch(prevStep());
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      {(hasDiets || hasOthers) && (
        <div>
          {diets.map(diet => (
            <OrderItem key={diet['@id']} diet={diet} />
          ))}
          {hasOthers && <OrderItem others={others} />}
        </div>
      )}
      {!isDown1200 && <AddMoreDietButton wrapperTwStyle={tw`my-4 sm:my-6`} />}
      <Box
        size="md"
        styles={{
          css: tw`relative mt-4 lg:mt-0 mb-4 lg:mb-6`,
        }}
      >
        <h3 className="h3" tw="pb-1.5 mb-4 border-b border-gray-1">
          {t('$*newOrderCreatePage.orderForm.summary.title')}
        </h3>
        <FinancialOptions />
      </Box>
      {!isDown1200 && (
        <Button
          onClick={handlePrevTab}
          disabled={false}
          variant="outlined"
          styles={{
            button: tw`mt-5`,
          }}
          data-cy="order-tabs__button--prev"
        >
          {t('$*components.orderTabs.prevButton.label')}
        </Button>
      )}
    </>
  );
};

export default OrderItems;
