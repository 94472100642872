import { useTranslation } from 'next-i18next';

import { useSelector } from 'react-redux';
import { selectors as basketNewOrderSelectors } from '@store/basket/basketNewOrder.slice';

import InfoIcon from '@components/elements/InfoIcon/InfoIcon';
import BASKET_ROWS_TYPES from '@constants/basketRowsTypes';
import TAB_SLUGS from '@constants/tabSlugs';
import { selectTabs } from '@features/orderTabs/orderTabsSlice';
import useBasket from '@hooks/useBasket';
import { useNewOrderCreatePageContext } from '@views/NewOrder/NewOrderCreatePage/NewOrderCreatePage.context';

const MultipleDietsBanner = () => {
  const { t } = useTranslation();
  const { isRedirectedAfterAddMoreDiet } =
    useNewOrderCreatePageContext() as unknown as {
      isRedirectedAfterAddMoreDiet: boolean;
    };

  const { activeOrderDietIri } = useSelector(
    basketNewOrderSelectors.selectBasket
  );

  const { currentStepSlug } = useSelector(selectTabs);

  const { data: basket } = useBasket({
    basketKey: 'basketNewOrder',
  });

  const dietElements =
    basket.rows?.filter(row => row['@type'] === BASKET_ROWS_TYPES.DIET_ITEM) ||
    [];

  const activeDiet = dietElements?.find(
    dietElement => dietElement['@id'] === activeOrderDietIri
  );

  if (!activeDiet || dietElements.length <= 1) {
    return null;
  }

  const innerText = (() => {
    if (currentStepSlug === TAB_SLUGS.SELECT_DIET) {
      if (isRedirectedAfterAddMoreDiet) {
        return t('$*components.multipleDietsBanner.newDiet');
      } else {
        return t('$*components.multipleDietsBanner.editDiet');
      }
    }

    if (
      ![
        TAB_SLUGS.SELECT_DIET,
        TAB_SLUGS.AUTHORIZATION,
        TAB_SLUGS.DELIVERY_DATA,
        TAB_SLUGS.SUMMARY,
      ].includes(currentStepSlug)
    ) {
      return t('$*components.multipleDietsBanner.particularDiet', {
        dietName: activeDiet.diet.name,
        calorificName: activeDiet.calorific.name,
      });
    }

    return '';
  })();

  if (!innerText) {
    return null;
  }

  return (
    <div tw="bg-white rounded-md shadow-lg mb-8 relative flex items-center p-4 overflow-hidden">
      <InfoIcon />
      <span tw="ml-2">{innerText}</span>
      <span tw="absolute left-0 bottom-0 h-1 bg-primary w-full" />
    </div>
  );
};

export default MultipleDietsBanner;
