import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

type PickUpPoint = {
  '@id': string;
  '@type': string;
  id: number;
  value: string;
};

const getPickupPoints = async () => {
  const response = await axios.get(ENDPOINT.DELIVERY_PICKUP_POINTS);

  return response?.data?.['hydra:member'] ?? [];
};

const usePickupPoints = (options = {}) => {
  return useQuery<PickUpPoint[]>({
    queryKey: ['pickupPoints'],
    queryFn: getPickupPoints,
    ...options,
  });
};

export default usePickupPoints;
