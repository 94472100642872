import { useTranslation } from 'next-i18next';

import { useDispatch } from 'react-redux';
import { setActiveOrderDietIri } from '@store/basket/basketNewOrder.slice';

import Modal from '@components/elements/Modal/Modal';
import ModalDietTile from '@components/modules/ChooseDietToEditModal/BasketDietTile';
import BASKET_ROWS_TYPES from '@constants/basketRowsTypes';
import TAB_SLUGS from '@constants/tabSlugs';
import useBasket from '@hooks/useBasket';
import useDateServiceWithLocale from '@hooks/useDateServiceWithLocale';
import useOrderForm from '@hooks/useOrderForm';

type ChooseDietToEditModalProps = {
  activatedTabSlug: string;
  isOpen: boolean;
  handleClose: () => void;
  handleAfterClose: () => void;
};

const ChooseDietToEditModal = ({
  activatedTabSlug,
  isOpen,
  handleClose,
  handleAfterClose,
}: ChooseDietToEditModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { formatWithLocale } = useDateServiceWithLocale();
  const { data: basket } = useBasket({
    basketKey: 'basketNewOrder',
  });
  const { data: orderForm } = useOrderForm();

  const dietElements =
    basket.rows?.filter(row => row['@type'] === BASKET_ROWS_TYPES.DIET_ITEM) ??
    [];

  if (dietElements.length <= 1 || !orderForm?.diets) {
    return null;
  }

  return (
    <Modal onClose={handleClose} isOpen={isOpen}>
      <Modal.Header>
        {t('$*newOrderCreatePage.chooseDiet.modalTitle')}
      </Modal.Header>
      <Modal.Content>
        {dietElements.map(basketDiet => {
          const findDate = (type: 'earliest' | 'latest') => {
            const mathFunction = type === 'earliest' ? Math.min : Math.max;

            return (
              formatWithLocale(
                mathFunction(
                  ...basketDiet.days.map(date => new Date(date).getTime())
                ),
                'dd.MM.yyyy'
              ) || ''
            );
          };

          const allowStepWithAdditionalMealTypes = orderForm.diets.find(
            offerDiet => offerDiet['@id'] === basketDiet.diet['@id']
          )?.allowStepWithAdditionalMealTypes;

          const hasDietsToSelectMenu = orderForm.diets.find(offerDiet => {
            return offerDiet['@id'] === basketDiet.diet['@id'];
          })?.hasDietsToSelectMenu;

          const isDisabled =
            (activatedTabSlug === TAB_SLUGS.ADDITIONAL_MEALTYPES &&
              !allowStepWithAdditionalMealTypes) ||
            (activatedTabSlug === TAB_SLUGS.CHOICES_DISH &&
              !hasDietsToSelectMenu);

          const imageUrl =
            orderForm.diets.find(
              offerDiet => offerDiet['@id'] === basketDiet.diet['@id']
            )?.clientImage || '';

          return (
            <ModalDietTile
              isDisabled={isDisabled}
              key={basketDiet['@id']}
              imageUrl={imageUrl}
              offerName={basketDiet.diet.name}
              customName={basketDiet.name}
              variant={basketDiet.variant.name}
              calorific={basketDiet.calorific.name}
              dateFrom={findDate('earliest')}
              dateTo={findDate('latest')}
              dietLength={basketDiet.dietLength}
              onClick={e => {
                e.stopPropagation();

                if (isDisabled) {
                  return;
                }

                dispatch(setActiveOrderDietIri(basketDiet['@id']));
                handleClose();
                handleAfterClose();
              }}
            />
          );
        })}
      </Modal.Content>
    </Modal>
  );
};

export default ChooseDietToEditModal;
