import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import tw from 'twin.macro';

import Box from '@components/elements/Box';
import DisplayUpXl from '@components/elements/DisplayOn/DisplayUpXl';
import HoverBasket from '@components/elements/HoverBasket';
import Tab from '@components/elements/Tabs/Tab';
import TabPanel from '@components/elements/Tabs/TabPanel';
import Tabs from '@components/elements/Tabs/Tabs';
import Basket from '@components/modules/Basket/Basket';
import { setIsCurrentValid } from '@features/orderTabs/orderTabsSlice';
import useAppModuleConfig from '@hooks/useAppModuleConfig';
import useBasket from '@hooks/useBasket';
import useBasketMethods from '@hooks/useBasketMethods';
import Addresses from '@views/NewOrder/NewOrderCreatePage/components/Delivery/Addresses/Addresses';
import PickUpPoints from '@views/NewOrder/NewOrderCreatePage/components/Delivery/PickUpPoints/PickUpPoints';

const DELIVERY_TYPE_TABS = {
  ADDRESS: 0,
  PICKUP_POINT: 1,
} as const;

const Delivery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { createBasketFromStaticBasket, isBasketStatic } =
    useBasketMethods() as unknown as {
      createBasketFromStaticBasket: (overrideDiet?: {
        [key: string]: unknown;
      }) => Promise<void>;
      isBasketStatic: boolean;
    };

  const {
    data: { defaultAddress, defaultPickUpPoint },
  } = useBasket({
    basketKey: 'basketNewOrder',
  });

  const {
    deliveryOptions: {
      address: isAddressEnabled,
      pickUpPoint: isPickUpPointEnabled,
    },
  } = useAppModuleConfig('ConfigClientPanel');

  const [isCreatingBasket, setIsCreatingBasket] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(
    defaultPickUpPoint
      ? DELIVERY_TYPE_TABS.PICKUP_POINT
      : DELIVERY_TYPE_TABS.ADDRESS
  );

  const isDeliverySelected = !!(defaultAddress || defaultPickUpPoint);

  useEffect(() => {
    dispatch(setIsCurrentValid(isDeliverySelected));
  }, [isDeliverySelected]);

  useEffect(() => {
    (async () => {
      if (isBasketStatic) {
        setIsCreatingBasket(true);
        await createBasketFromStaticBasket();
        setIsCreatingBasket(false);
      }
    })();
  }, [isBasketStatic]);

  return (
    <div tw="flex justify-end">
      <div className="col--main-center">
        <Box>
          <Tabs
            cssExtend={tw`mb-7`}
            activeTabIndex={activeTabIndex}
            onChange={(
              tabIndex: (typeof DELIVERY_TYPE_TABS)[keyof typeof DELIVERY_TYPE_TABS]
            ) => {
              setActiveTabIndex(tabIndex);
            }}
          >
            {isAddressEnabled && (
              <Tab
                label={t(
                  '$*newOrderCreatePage.orderForm.delivery.addressDelivery'
                )}
                index={DELIVERY_TYPE_TABS.ADDRESS}
                data-cy="tab-delivery--address"
              />
            )}
            {isPickUpPointEnabled && (
              <Tab
                label={t(
                  '$*newOrderCreatePage.orderForm.delivery.pickupAtPoint'
                )}
                index={DELIVERY_TYPE_TABS.PICKUP_POINT}
                data-cy="tab-delivery--pickup-point"
              />
            )}
          </Tabs>
          {isAddressEnabled && (
            <TabPanel
              activeTabIndex={activeTabIndex}
              index={DELIVERY_TYPE_TABS.ADDRESS}
            >
              <Addresses isLoading={isCreatingBasket} />
            </TabPanel>
          )}
          {isPickUpPointEnabled && (
            <TabPanel
              activeTabIndex={activeTabIndex}
              index={DELIVERY_TYPE_TABS.PICKUP_POINT}
            >
              <PickUpPoints isLoading={isCreatingBasket} />
            </TabPanel>
          )}
        </Box>
      </div>
      <DisplayUpXl>
        <div className="col--main-right">
          <div tw="sticky top-28 z-20">
            <Basket />
            <HoverBasket />
          </div>
        </div>
      </DisplayUpXl>
    </div>
  );
};

export default Delivery;
